import STOCK, { TStock } from '../../constants/stock.constant';
import {
	Image1,
	Image10,
	Image10Thumb,
	Image11,
	Image11Thumb,
	Image12,
	Image12Thumb,
	Image13,
	Image13Thumb,
	Image14,
	Image14Thumb,
	Image15,
	Image15Thumb,
	Image16,
	Image16Thumb,
	Image17,
	Image17Thumb,
	Image18,
	Image18Thumb,
	Image19,
	Image19Thumb,
	Image1Thumb,
	Image2,
	Image2Thumb,
	Image3,
	Image3Thumb,
	Image4,
	Image4Thumb,
	Image5,
	Image5Thumb,
	Image6,
	Image6Thumb,
	Image7,
	Image7Thumb,
	Image8,
	Image8Thumb,
	Image9,
	Image9Thumb,
} from '../../assets/images';
import usersDb, { TUser } from './users.db';
import { categoriesDbList, TCategory } from './categories.db';
import tagsDb, { TTag } from './tags.db';

export type TProduct = {
	views: number;
	category: TCategory;
	tag?: TTag['id'][];
	description?: string;
	id: string;
	image: {
		org: string;
		thumb: string;
	};
	price: number;
	productName: string;
	likes: number;
	stock: TStock;
	user: TUser;
	SKU: string;
};

const productsDb: TProduct[] = [
	{
		id: '1',
		productName: 'Joystick TX',
		price: 24,
		views: 100,
		stock: STOCK.IN,
		likes: 50,
		image: {
			org: Image1,
			thumb: Image1Thumb,
		},
		description:
			'[{"type":"paragraph","children":[{"text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam malesuada nisl sed metus maximus imperdiet. Aenean tortor mi, pretium et faucibus elementum, pulvinar ultricies ex. Vivamus pharetra dui interdum, semper diam eget, blandit urna. Etiam eu tristique leo. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In eros mi, vehicula sed ex sed, accumsan posuere leo. Vestibulum auctor aliquam elit, ut maximus felis gravida in. Donec feugiat sit amet est egestas porttitor. Suspendisse egestas nisi nec urna consequat, quis lobortis elit interdum. Pellentesque purus nibh, dignissim porta tincidunt id, convallis id lectus. In varius ipsum non turpis suscipit, ac ultrices nisi congue. Phasellus eget lectus eget dui sodales sollicitudin ut a nisi."}]}]',
		user: usersDb[0],
		category: categoriesDbList.technology,
		SKU: 'SKU01',
		tag: [tagsDb[0].id, tagsDb[3].id],
	},
	{
		id: '2',
		productName: 'Thermos MC 2',
		price: 40,
		views: 45,
		stock: STOCK.OUT_OF,
		likes: 80,
		image: {
			org: Image4,
			thumb: Image4Thumb,
		},
		user: usersDb[0],
		category: categoriesDbList.glassware,
		SKU: 'SKU02',
	},
	{
		id: '3',
		productName: 'Shoe Fuel',
		price: 45,
		views: 80,
		stock: STOCK.LIMITED,
		likes: 10,
		image: {
			org: Image3,
			thumb: Image3Thumb,
		},
		user: usersDb[0],
		category: categoriesDbList.shoes,
		SKU: 'SKU03',
	},
	{
		id: '4',
		productName: 'Pen D5000',
		price: 45,
		views: 20,
		stock: STOCK.CRITICAL,
		likes: 10,
		image: {
			org: Image5,
			thumb: Image5Thumb,
		},
		user: usersDb[0],
		category: categoriesDbList.stationery,
		SKU: 'SKU04',
	},
	{
		id: '5',
		productName: 'Headphone MDR',
		price: 45,
		views: 70,
		stock: STOCK.IN,
		likes: 10,
		image: {
			org: Image6,
			thumb: Image6Thumb,
		},
		user: usersDb[0],
		category: categoriesDbList.technology,
		SKU: 'SKU05',
	},
	{
		id: '6',
		productName: 'Headphone RX12',
		price: 60,
		views: 57,
		stock: STOCK.IN,
		likes: 10,
		image: {
			org: Image2,
			thumb: Image2Thumb,
		},
		user: usersDb[0],
		category: categoriesDbList.technology,
		SKU: 'SKU06',
	},
	{
		id: '7',
		productName: 'Headphone J&J',
		price: 74,
		views: 36,
		stock: STOCK.IN,
		likes: 10,
		image: {
			org: Image7,
			thumb: Image7Thumb,
		},
		user: usersDb[0],
		category: categoriesDbList.technology,
		SKU: 'SKU07',
	},
	{
		id: '8',
		productName: 'Sunglasses Patek',
		price: 24,
		views: 86,
		stock: STOCK.IN,
		likes: 10,
		image: {
			org: Image8,
			thumb: Image8Thumb,
		},
		user: usersDb[0],
		category: categoriesDbList.fashion,
		SKU: 'SKU08',
	},
	{
		id: '9',
		productName: 'Headphone PRO T9',
		price: 44,
		views: 44,
		stock: STOCK.IN,
		likes: 10,
		image: {
			org: Image9,
			thumb: Image9Thumb,
		},
		user: usersDb[0],
		category: categoriesDbList.technology,
		SKU: 'SKU09',
	},
	{
		id: '10',
		productName: 'Headphone S880',
		price: 67,
		views: 74,
		stock: STOCK.IN,
		likes: 10,
		image: {
			org: Image10,
			thumb: Image10Thumb,
		},
		user: usersDb[0],
		category: categoriesDbList.technology,
		SKU: 'SKU10',
	},
	{
		id: '11',
		productName: 'Sunglasses Daniel',
		price: 299,
		views: 25,
		stock: STOCK.IN,
		likes: 10,
		image: {
			org: Image11,
			thumb: Image11Thumb,
		},
		user: usersDb[0],
		category: categoriesDbList.fashion,
		SKU: 'SKU11',
	},
	{
		id: '12',
		productName: 'Glass Stockholm',
		price: 12,
		views: 64,
		stock: STOCK.IN,
		likes: 10,
		image: {
			org: Image12,
			thumb: Image12Thumb,
		},
		user: usersDb[0],
		category: categoriesDbList.glassware,
		SKU: 'SKU12',
	},
	{
		id: '13',
		productName: 'GameJoy M3',
		price: 99,
		views: 34,
		stock: STOCK.IN,
		likes: 10,
		image: {
			org: Image13,
			thumb: Image13Thumb,
		},
		user: usersDb[0],
		category: categoriesDbList.technology,
		SKU: 'SKU13',
	},
	{
		id: '14',
		productName: 'Stiletto by Zanna',
		price: 99,
		views: 45,
		stock: STOCK.IN,
		likes: 10,
		image: {
			org: Image14,
			thumb: Image14Thumb,
		},
		user: usersDb[0],
		category: categoriesDbList.shoes,
		SKU: 'SKU14',
	},
	{
		id: '15',
		productName: 'Watch W6',
		price: 119,
		views: 25,
		stock: STOCK.IN,
		likes: 10,
		image: {
			org: Image15,
			thumb: Image15Thumb,
		},
		user: usersDb[0],
		category: categoriesDbList.watch,
		SKU: 'SKU15',
	},
	{
		id: '16',
		productName: 'Polaroid P8',
		price: 149,
		views: 84,
		stock: STOCK.IN,
		likes: 10,
		image: {
			org: Image16,
			thumb: Image16Thumb,
		},
		user: usersDb[0],
		category: categoriesDbList.technology,
		SKU: 'SKU16',
	},
	{
		id: '17',
		productName: 'Speaker DA7',
		price: 33,
		views: 43,
		stock: STOCK.IN,
		likes: 10,
		image: {
			org: Image17,
			thumb: Image17Thumb,
		},
		user: usersDb[0],
		category: categoriesDbList.technology,
		SKU: 'SKU17',
	},
	{
		id: '18',
		productName: 'Headphone BT900',
		price: 43,
		views: 45,
		stock: STOCK.IN,
		likes: 10,
		image: {
			org: Image18,
			thumb: Image18Thumb,
		},
		user: usersDb[0],
		category: categoriesDbList.technology,
		SKU: 'SKU18',
	},
	{
		id: '19',
		productName: 'GameBox X3',
		price: 499,
		views: 67,
		stock: STOCK.IN,
		likes: 10,
		image: {
			org: Image19,
			thumb: Image19Thumb,
		},
		user: usersDb[0],
		SKU: 'SKU19',
		category: categoriesDbList.technology,
	},
];

export default productsDb;
