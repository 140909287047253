import { isExpired, decodeToken } from "react-jwt";

export class AuthService {
  // Function to perform login and store access token in local storage
  async login(email: string, password: string): Promise<boolean> {
    try {
      // Perform API call to login
      const response = await fetch('https://identity.dev.platform.vizhuall.com/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        // Handle login error, e.g., display error message
        console.error('Login failed');
        return false;
      }

      // Parse response JSON
      const data = await response.json();

      // Store access token in local storage
      localStorage.setItem('accessToken', data.accessToken);

      return true;
    } catch (error) {
      console.error('Error during login', error);
      return false;
    }
  }

  // Function to check if access token is expired and delete it
  checkAndDeleteExpiredToken(): void {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      try {
        const test =  decodeToken(accessToken);
        const tokenIsExpired = isExpired(accessToken);
        if (tokenIsExpired) {
          localStorage.removeItem('accessToken');
        }
      } catch (error) {
        console.error('Error decoding access token', error);
      }
    }
  }

  // Function to check if the user is authenticated
  isAuthenticated(): boolean {
    this.checkAndDeleteExpiredToken();
    const accessToken = localStorage.getItem('accessToken');
    return !!accessToken;
  }
}
