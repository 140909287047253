import React, { SVGProps } from 'react';

const SvgAtSymbol = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		className='svg-icon'
		{...props}>
		<path
			d='M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25'
			stroke='currentColor'
			strokeWidth={1.5}
			strokeLinecap='round'
		/>
	</svg>
);
export default SvgAtSymbol;
